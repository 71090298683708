const CONFIG = {
  client: {
    name: process.env.REACT_APP_CLIENT_NAME || "X1",
  },
  linking: {
    base_url:
      process.env.REACT_APP_AMAZON_API_BASE_URL || "https://api.amazon.com",
    scope: "amazon_music:access",
  },
  player: {
    heartbeat_frequency: 3000,
    timeout_check_frequency: 30000,
    disable_time_updates: false,
  },
  music: {
    base_url:
      process.env.REACT_APP_AMAZON_MUSIC_BASE_URL ||
      "https://music-api.amazon.com",
    post_base_url:
      process.env.REACT_APP_AMAZON_MUSIC_POST_BASE_URL ||
      process.env.REACT_APP_AMAZON_MUSIC_BASE_URL ||
      "https://music-api.amazon.com",
    browse_node: "/widescreen_catalog/",
    recent_node: "/widescreen_recents/",
    my_music_node: "/widescreen_library/",
    search: "/wisescreen_search/",
  },
  auth: {
    endpoint:
      `${process.env.REACT_APP_AMAZON_API_BASE_URL}/auth/O2/` ||
      "https://api.amazon.com/auth/O2/",
  },
  transcode: {
    image_url: `${process.env.REACT_APP_AMAZON_CATCH_URL}`,
  },
  error_modal_timeout: 5000,
  default_language: "*",
  default_language_header:
    process.env.REACT_APP_CLIENT_NAME === "RO" ? "en-CA" : "*",
};

export default CONFIG;
